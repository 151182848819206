<template>
  <div v-if="modal" class="modal">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-nColorButtonText bg-opacity-30 modal-backdrop"
    >
      <div
        class="modal-width bg-nColorTextInput border border-nColorBorder rounded-3xl text-white"
        v-if="data"
      >
        <div class="bg-nColorTextInput rounded-3xl">
          <div
            class="flex justify-between text-3xl font-InterSemiBold text-white bg-nColorButtonText rounded-t-3xl px-8 py-4"
          >
            <div>
              <h3 id="modal-headline">
                <div class="flex flex-row items-center gap-2">
                  <div class="text-2xl">
                    {{ data.name.name }}
                  </div>
                </div>
              </h3>
            </div>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
                alt="DIY Modal close"
                width="30"
                height="30"
                class="cursor-pointer"
                @click="closeModal"
              />
            </div>
          </div>
          <div class="flex gap-2 items-start px-4" v-if="data.step4">
            <div class="w-full p-4">
              <div class="font-InterSemiBold text-base text-white">
                Share the link & QR code with your crew prior to the Game Jam.
              </div>
              <div class="flex gap-4 items-center mt-4">
                <div class="grid gap-2">
                  <div v-if="data.step4.qrcode">
                    <img :src="data.step4.qrcode" width="180" height="180" />
                  </div>
                  <div>
                    <button
                      class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-xl w-full"
                      @click.prevent="downloadQR(data.game_jam_id)"
                    >
                      Download QR Code
                    </button>
                  </div>
                </div>
                <div class="grid gap-2">
                  <p class="text-white font-InterSemiBold">Game Jam Link</p>
                  <p class="text-nColorLightGray font-InterMedium text-sm">
                    This Link can be shared with your peers to log into Playda!
                  </p>
                  <div
                    class="bg-nColorButtonText rounded-xl px-4 py-2 flex justify-between items-center"
                  >
                    <p
                      class="text-nColorOrange font-InterExtraBold italic break-all w-3/4"
                    >
                      {{ data.step4.link }}
                    </p>
                    <div>
                      <button
                        class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow rounded-3xl text-lg px-3"
                        v-if="data.step4.link"
                        @click="copyLink(data.step4.link)"
                      >
                        copy link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "QRCodeAndLinksModal",
  components: {},
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    copyLink(link) {
      const textarea = document.createElement("textarea");
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Link copied to clipboard!", { timeout: 2000 });
    },
    downloadQR(gameJamId) {
      ApiService.post(apiResource.downloadQRCode, {
        game_jam_id: gameJamId,
      })
        .then((data) => {
          const imageUrl = data.data.data.url; // Presigned URL
          fetch(imageUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.blob(); // Convert response to Blob
            })
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob); // Create a URL for the Blob
              link.download =
                "Playda Game Jam - " + data.data.data.name + ".png"; // Set the file name
              document.body.appendChild(link); // Append to the body
              link.click(); // Trigger download
              document.body.removeChild(link); // Clean up
              URL.revokeObjectURL(link.href); // Clean up the object URL
            })
            .catch((error) => {
              console.error("Download failed:", error); // Log any errors
            });
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },
  },
};
</script>

<style scoped>
.modal-width {
  width: 820px;
}
.mobile-height {
  height: 75%;
}
.modal-backdrop {
  backdrop-filter: blur(2px);
}
</style>
